'use client';

import ReactDOM from 'react-dom';
import { FONT_PRELOADS } from '$constants/font-preloads';

/**
 * @link https://nextjs.org/docs/app/api-reference/functions/generate-metadata#resource-hints
 */
export function PreloadResources(): null {
  for (const { url } of FONT_PRELOADS) {
    ReactDOM.preload(url, { as: 'font' });
  }

  return null;
}
