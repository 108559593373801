import type { ParsedUrlQuery } from 'querystring';
import type { UrlObject } from 'url';
import config from 'config';
import type { NextApiRequest } from 'next';
import { pickBy, isString, throttle } from 'lodash';
import store from 'store';
import Cookies from 'js-cookie';
import type { ParsedQs } from 'qs';
import qs from 'qs';

export * from './basic';
export * from './logger';

/**
 * Don't use this for render conditions, instead use `useRenderAfterHydration`.
 * @deprecated use from src folder
 */
export const isBrowser = typeof window !== 'undefined';
export const isWidget = (): boolean => {
  if (!isBrowser) return false;
  return window.location.pathname.includes('/widgets-');
};
export const isBlog = (): boolean => {
  if (!isBrowser) return false;
  return window.location.pathname.includes('/blog/');
};

/**
 * @deprecated use `IS_LOCAL_RUNTIME` instead
 */
export const isLocalhost = (url?: string): boolean => {
  if (!process.browser && process.env.NEXT_PUBLIC_LOCAL) return true;
  if (url) return url.includes('localhost') || url.includes('hellofixter');
  if (!process.browser) return false;
  return window.location.hostname === 'localhost' || window.location.hostname.includes('hellofixter');
};

export const makeURL = ({ pathname, query }: UrlObject): string => {
  if (!pathname) throw new TypeError('pathname required to make URL');
  let url = pathname;
  const stringQuery = qs.stringify(query);
  if (stringQuery) url += `?${stringQuery}`;
  return url;
};

/**
 * Alternative to `router.push` to actually navigate to page instead of rendering page in background.
 * This will be called only once per 5s to make sure we don't spam with redirects.
 * BUG: `router.push` loses context for S3 assets.
 * @param param keep object compatibility with `router.push`
 */
export const changeUrl = throttle((urlObj: UrlObject) => {
  if (!process.browser) return;
  const url = makeURL(urlObj);
  if (!url) return;
  window.location.href = url;
}, 5000);

export const redirect = throttle((urlObj: UrlObject, defaultUrl = '/') => {
  if (typeof window === 'undefined') return;
  window.location.href = urlObj?.href ?? defaultUrl;
}, 5000);

export const getUrlObject = (url: string, defaultUrl = '/'): UrlObject => {
  const baseUrl: string = window.location.origin;
  try {
    return new URL(decodeURI(url), baseUrl);
  } catch (err) {
    return new URL(defaultUrl, baseUrl);
  }
};

/**
 * Return only the string values from a url query.
 */
export const parseStringUrlQuery = (
  urlQuery: ParsedUrlQuery | ParsedQs
): Record<string, string | undefined> => pickBy(urlQuery, isString);

// Create a unique key-value object from params, taking the first string value if the value is an array of strings, { gclid: ['123', '123'] } => { gclid: '123' }
export const createUniqueParams = (params: Record<string, string | string[]>): Record<string, string> => {
  const uniqueParams = Object.entries(params).reduce((acc: Record<string, string>, [key, value]) => {
    if (Array.isArray(value) && isString(value[0])) {
      const [firstValue] = value;
      acc[key] = firstValue;
    } else if (isString(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return uniqueParams;
};

export const getUrlQueryParams = () => {
  const params = process.browser ? qs.parse(window.location.search, { ignoreQueryPrefix: true }) : {};
  const uniqueParams = createUniqueParams(params as Record<string, string | string[]>);
  return uniqueParams;
};
/**
 * Returns only string values for the current URL query params
 */
export const getStringUrlQueryParams = () => parseStringUrlQuery(getUrlQueryParams());

/**
 * Our standard requests to Next API include query data param.
 * This reads parsed query data.
 */
export const parseNextApiQueryData = <T>(req: NextApiRequest): T =>
  JSON.parse(parseStringUrlQuery(req.query).data || '{}') as T;

export const getPartner = (): string | undefined => {
  const acquisitionMethod = Cookies.get('acquisitionMethod');
  if (acquisitionMethod !== 'partner') return undefined;
  return Cookies.get('fixterPartner');
};

export const isPartner = (partner: string): boolean => partner === getPartner();

export const getAccessToken = (): string | undefined => Cookies.getJSON('fixterAccessToken');

export type StageEnv = 'development' | 'staging' | 'production';

export const getEnvStage = (): StageEnv => config.get<StageEnv>('public.environment');

/**
 * @deprecated use `isEnvStage` from `@/util`
 */
export const isEnvStage = (stage: StageEnv): boolean => getEnvStage() === stage;

/**
 * @deprecated use `isEnvStage(stage)`
 */
export const isEnvDev = isEnvStage('development');

/**
 * Save from what page the session was started.
 */
export const storeStartSessionUrl = (): void => {
  store.set('startSessionUrl', window.location.href);
};

/**
 * Get from what page the session was started.
 */
export const getStartSessionUrl = (): string => store.get('startSessionUrl');

export const resetStartSessionUrl = (): void => {
  store.remove('startSessionUrl');
};
