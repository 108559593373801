'use client';

import Script from 'next/script';
import type { ReactNode } from 'react';
import React from 'react';
import { useWebVitalEventTimeout } from '$util/hooks/useWebVitalEventTimeout';

/**
 * Extracted in this function to be able to use it on Page Router too
 */
export const makeAwsRumScript = (
  id: string,
  identityPoolId: string
): string => `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
  'cwr',
  '${id}',
  '1.0.0',
  'eu-west-1',
  'https://client.rum.us-east-1.amazonaws.com/1.16.1/cwr.js',
  {
    sessionSampleRate: 0.5,
    identityPoolId: '${identityPoolId}',
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance"],
    allowCookies: true,
    enableXRay: true
  }
);`;

interface AwsRumProps {
  readonly id: string;
  readonly identityPoolId: string;
  readonly nonce?: string;
}

export function AwsRum({ id, identityPoolId, nonce }: AwsRumProps): ReactNode {
  const ready = useWebVitalEventTimeout('FCP', 1000);
  if (!ready) return null;

  return (
    <Script id="aws-rum" nonce={nonce}>
      {makeAwsRumScript(id, identityPoolId)}
    </Script>
  );
}
