'use client';

import { useMemo } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import type { Locale } from '@fixter/i18n';
import { IS_LOCAL_RUNTIME } from '@/util';
import { AppContext, type AppContextData } from './AppContext';

interface AppContextProviderProps {
  hostname: string;
  locale: Locale;
  children: React.ReactNode;
}

/**
 * A Context Provider can be used in a Server Component only if it's wrapped in a Client Component.
 */
export function AppContextProvider({ hostname, locale, children }: AppContextProviderProps): JSX.Element {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const ctx: AppContextData = useMemo(() => {
    const protocol = IS_LOCAL_RUNTIME ? 'http' : 'https';
    const searchParamsString = searchParams?.toString();
    const params = searchParamsString ? `?${searchParamsString}` : '';

    return {
      locale,
      location: new URL(`${protocol}://${hostname}${pathname}${params}`),
    };
  }, [searchParams, locale, hostname, pathname]);

  return <AppContext.Provider value={ctx}>{children}</AppContext.Provider>;
}
