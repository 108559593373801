'use client';

import { usePathname } from 'next/navigation';
import { makeAmazonAdsPageViewScriptContents } from './utils';

interface AmazonAdsProps {
  readonly id: string;
  readonly pathname?: string;
}

export function AmazonAds({ id, pathname }: AmazonAdsProps): JSX.Element {
  let urlPath = usePathname();
  if (pathname) {
    urlPath = pathname;
  }

  let trackEvent;
  let page;
  if (urlPath?.startsWith('/checkout')) {
    const pagePath = /\/checkout\/(\w+)/.exec(urlPath);
    page = pagePath?.[1];

    switch (page) {
      case 'quotes':
        trackEvent = 'Search';
        break;
      case 'schedule':
        trackEvent = 'Lead';
        break;
      case 'personaldetails':
        trackEvent = 'Signup';
        break;
      case 'payment':
        trackEvent = 'Checkout';
        break;
      case 'success':
        trackEvent = 'Off-AmazonPurchases';
        break;
      default:
        trackEvent = undefined;
    }
  }

  return (
    <>
      <script
        id={`amzn-${page || 'page'}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: makeAmazonAdsPageViewScriptContents(id, trackEvent),
        }}
      >
        {}
      </script>
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={`https://aax-eu.amazon-adsystem.com/s/iu3?pid=${id}&event=PageView`}
          width="1"
          height="1"
          alt=""
        />
        {trackEvent && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={`https://aax-eu.amazon-adsystem.com/s/iu3?pid=${id}&event=${trackEvent}`}
            width="1"
            height="1"
            alt=""
          />
        )}
      </noscript>
    </>
  );
}
