'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState, type ReactNode } from 'react';

/**
 * Use this to stop the Anti Flicker screen before its timeout.
 * eg. An AB test component was mounted faster than the timeout.
 */
export const stopAntiFlicker = (): void => {
  const event = new CustomEvent('stop-anti-flicker');
  document.body.dispatchEvent(event);
};

interface KameleoonAntiFlickerProps {
  readonly loadingTimeout: number;
  readonly paths: string[];
}

export function KameleoonAntiFlicker({ loadingTimeout, paths }: KameleoonAntiFlickerProps): ReactNode {
  const pathname = usePathname() ?? '';
  const timeoutRef = useRef<number>();

  /**
   * To enable for all pages use `/*`.
   */
  const enabledForPath = Boolean(paths.length) && (paths[0] === '/*' || paths.includes(pathname));

  const [showAntiFlicker, setShowAntiFlicker] = useState(enabledForPath);

  useEffect(() => {
    if (enabledForPath && !timeoutRef.current) {
      const removeAntiFlicker = () => {
        setShowAntiFlicker(false);
        document.body.removeEventListener('stop-anti-flicker', removeAntiFlicker);
        clearTimeout(timeoutRef.current);
      };
      document.body.addEventListener('stop-anti-flicker', removeAntiFlicker);
      timeoutRef.current = window.setTimeout(removeAntiFlicker, loadingTimeout);
    }
  }, [enabledForPath, loadingTimeout]);

  return (
    showAntiFlicker && (
      <style id="kameleoon-anti-flicker" type="text/css">
        {`html { visibility: hidden !important; background-image: none !important; }`}
      </style>
    )
  );
}
