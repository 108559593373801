import { set, unset, get } from 'lodash';
import { isObjectType } from '../isObjectType';

/**
 * Removes all the `null` values in an object recursively.
 * Useful for working with GraphQL results where `undefined` values are always set as `null`.
 * NOTE: mutates object if it's writable or returns a new one.
 */
export const removeNullValues = <T extends object = Record<string, unknown>>(obj: T): NonNullable<T> =>
  isObjectType(obj)
    ? Object.keys(obj).reduce((o: T, k): NonNullable<T> => {
        // GraphQL results are not writable
        const writable = Boolean((Object.getOwnPropertyDescriptor(o, k) || {}).writable);
        const res = writable ? o : { ...o };
        const val = get(res, k);
        if (isObjectType(val)) {
          return set(res, k, removeNullValues(val));
        }
        if (Array.isArray(val)) {
          return set(
            res,
            k,
            val.map((a) => removeNullValues(a))
          );
        }
        if (val === null) {
          unset(res, k);
          return res;
        }
        return o;
      }, obj)
    : (obj as NonNullable<T>);
