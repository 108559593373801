'use client';

import { useEffect } from 'react';
import { sendAnalyticsPageView, sendAnalyticsGclid } from '@/util/internalAnalytics/client';

interface LayoutSetupEffectsClientProps {
  readonly isInternalAnalyticsEnabled: boolean;
}

export function LayoutSetupEffectsClient({
  isInternalAnalyticsEnabled,
}: LayoutSetupEffectsClientProps): null {
  useEffect(() => {
    if (isInternalAnalyticsEnabled) {
      sendAnalyticsPageView();
      sendAnalyticsGclid();
    }
  }, [isInternalAnalyticsEnabled]);

  return null;
}
