'use client';

import { useEffect } from 'react';
import Script from 'next/script';
import type { ReactNode } from 'react';
import { useWebVitalEventTimeout } from '$util/hooks/useWebVitalEventTimeout';
import { gtag } from '@/components/templates/ThirdPartyScripts/GoogleAnalytics/gtag';
import { ga4Event } from '@/util/ga4Analytics';
import { isFunctionalCategoryActive, isPerformanceCategoryActive, isTargetingCategoryActive } from './util';

type ConsentOption = 'denied' | 'granted';

interface OneTrustProps {
  src: string;
  domain: string;
  isGAEnabled: boolean;
}

export function OneTrust({ isGAEnabled, src, domain }: OneTrustProps): ReactNode {
  const ready = useWebVitalEventTimeout('FCP', 600);

  useEffect(() => {
    if (isGAEnabled && ready) {
      window.handleGoogleConsent = () => {
        const analyticsConsent: ConsentOption = isPerformanceCategoryActive() ? 'granted' : 'denied';
        const marketingConsent: ConsentOption = isTargetingCategoryActive() ? 'granted' : 'denied';
        const functionalConsent: ConsentOption = isFunctionalCategoryActive() ? 'granted' : 'denied';

        const consent = {
          analytics_storage: analyticsConsent,
          ad_storage: marketingConsent,
          ad_user_data: marketingConsent,
          ad_personalization: marketingConsent,
          functionality_storage: functionalConsent,
        };

        gtag('consent', 'update', consent);
        gtag('event', 'page_view', {
          page_path: window.location.pathname + window.location.search,
        });
        ga4Event.ready = true;
        const consentSetEvent = new CustomEvent('consent-set');
        document.body.dispatchEvent(consentSetEvent);
      };
    }
  }, [isGAEnabled, ready]);

  if (!ready) return null;

  return (
    <>
      <Script id="onetrust-script" src={src} data-domain-script={domain} data-document-language="true" />
      <Script id="ot-callback">
        {`
          function OptanonWrapper() {
            const isGA4Enabled = ${isGAEnabled};
            if (window.OneTrust) {
              if (isGA4Enabled) {
                window.handleGoogleConsent();
              }
            }
          }
        `}
      </Script>
    </>
  );
}
