'use client';

import { createContext, useContext } from 'react';
import type { AnyObject } from './types';

export const AppContext = createContext<AnyObject>({} as AnyObject);

export default AppContext;

/**
 * @deprecated use from `src` folder in App Router pages.
 */
export const useAppContext = <T = AnyObject>(): T => useContext(AppContext) as T;
