import { useState, useEffect } from 'react';

/**
 * @param ms
 * * negative: don't start the timer yet
 * * 0: instant ready
 * * positive: start the timer
 */
export function useTimeout(ms: number): boolean {
  const [ready, setIsReady] = useState(ms === 0);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!ready && ms) {
      timeoutId = setTimeout(() => {
        setIsReady(true);
      }, ms);
    }

    return () => clearTimeout(timeoutId);
  }, [ms, ready]);

  return ready;
}
