'use client';

import { useEffect } from 'react';
import { GoogleAnalytics as GoogleAnalyticsNext } from '@next/third-parties/google';
import type { ReactNode } from 'react';
import { useWebVitalEventTimeout } from '$util/hooks/useWebVitalEventTimeout';
import { gtag } from './gtag';

interface GoogleAnalyticsProps {
  gaId: string;
  floodlightId?: string;
}

export function GoogleAnalytics({ gaId, floodlightId }: GoogleAnalyticsProps): ReactNode {
  const ready = useWebVitalEventTimeout('FCP', 1000);

  useEffect(() => {
    gtag('consent', 'default', {
      analytics_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      functionality_storage: 'denied',
    });
    gtag('js', new Date());
    gtag('config', gaId, {
      send_page_view: false,
    });
    if (floodlightId) {
      gtag('config', floodlightId);
    }
  }, [gaId, floodlightId]);

  if (!ready) return null;

  return <GoogleAnalyticsNext gaId={gaId} />;
}
