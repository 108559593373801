import { get, memoize, once } from 'lodash';
import Cookies from 'js-cookie';
import config from 'config';
import { captureException } from '@sentry/nextjs';

/**
 * Log error to Sentry
 *
 * @note When this is used inside components that could re-render often and spam with errors
 * Use `logError()` with param `once`.
 */
const logSentryError = (error: string | Error, extra: Record<string, unknown> = {}): void => {
  console.error(error, extra);
  if (config.get('public.sentry.enabled')) {
    const err = error instanceof Error ? error : new Error(error);
    const debugData = { ...extra };
    const fixterSessionId = Cookies.get('fixterSessionId');
    if (fixterSessionId) debugData.sessionId = fixterSessionId;
    captureException(err, { extra: debugData });
  }
};

/**
 * Log error ONLY once to Sentry.
 * Useful when re-renders would spam same error.
 *
 * @note SSR this will only log once per instance restart and CSR once for each client.
 *
 * @deprecated Use `logError()` with param `once`
 * @todo remove export because it's internal function only for `logError()`
 */
export const logErrorOnce = memoize((...params: Parameters<typeof logSentryError>) =>
  once(() => logSentryError(...params))()
);

/**
 * Log error to Sentry
 *
 * @param logOnce Logs the error only once.\
 * Useful when this is used inside Client Components that could re-render often and spam with errors.
 *
 * @deprecated use from src folder
 */
export const logError = (error: string | Error, extra: Record<string, unknown> = {}, logOnce = true): void =>
  logOnce ? logErrorOnce(error, extra) : logSentryError(error, extra);

/**
 * I've seen errors where the logger creations has failed.
 * This is a fallback to not crash the page.
 */
const staleLogger = {
  error: () => undefined,
  warn: () => undefined,
  info: () => undefined,
  debug: () => undefined,
  trace: () => undefined,
  child: () => staleLogger,
};

/**
 * Create bunyan logger.
 *
 * @deprecated use `makeServerLogger()`
 */
export const getServerLogger = (name: string) => {
  if (process.browser) return undefined;
  if (!get(global, 'SERVER_PATH')) {
    return staleLogger;
  }
  // eslint-disable-next-line no-template-curly-in-string
  const bindings = eval('require(`${SERVER_PATH}/bindings`)();');
  return bindings.newObject('logger')(name) || staleLogger;
};

export const getLogger = (name: string) => {
  if (process.browser) {
    return staleLogger;
  }
  return getServerLogger(name);
};
