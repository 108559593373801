export const FONT_PRELOADS = [
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/jokker/Jokker-Bold.woff2',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/jokker/Jokker-Regular.woff2',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/jokker/Jokker-Semibold.woff2',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/rubik/rubik-v26-latin-regular.woff2',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/rubik/rubik-v26-latin-600.woff2',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/rubik/rubik-v26-latin-700.woff2',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/champ/Champ-SemiBold.otf',
  },
  {
    rel: 'preload',
    as: 'font',
    url: '/fonts/champ/Champ-Bold.otf',
  },
];
