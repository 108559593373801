import type { AnyObject } from '$util/types';
import { context } from '@/contexts/GlobalContext';

export * from './events';

type GA4EventOptions = Record<string, string | number | undefined> & {
  readonly trigger?: string | number | undefined;
};

export const ga4Event = (name: string, options?: GA4EventOptions | AnyObject): undefined => {
  if (context.get<boolean>('isGAEnabled')) {
    window.gtag('event', name, options);
  }
};

ga4Event.ready = false;

export const ga4Set = (options: AnyObject): undefined => {
  if (context.get<boolean>('isGAEnabled')) {
    window.gtag('set', options);
  }
};
