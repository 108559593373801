import { useState, useEffect } from 'react';
import { useReportWebVitals } from 'next/web-vitals';
import { useTimeout } from './useTimeout';

export function useWebVitalEventTimeout(metricName: string, delayLoad: number): boolean {
  const fallback = useTimeout(delayLoad);
  const [ready, setIsReady] = useState(fallback);

  useEffect(() => {
    if (!ready && fallback) {
      setIsReady(true);
    }
  }, [ready, fallback]);

  useReportWebVitals((metric) => {
    if (metric.name === metricName && !ready) {
      setIsReady(true);
    }
  });

  return ready;
}
