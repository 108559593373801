'use client';

import Script from 'next/script';
import { usePathname } from 'next/navigation';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';

export function ActiveCampaignLeadCapture({ scriptSrc }: { scriptSrc: string }): React.ReactNode {
  const pathname = usePathname() ?? '';
  const isHomepage = pathname === '/';
  const abTestActive = useActiveExperiment(ActiveExperiments.ACForm10Off, 'B');

  const shouldLoad = abTestActive && scriptSrc && isHomepage;

  if (!shouldLoad) return null;

  return <Script src={scriptSrc} charSet="utf-8" />;
}
