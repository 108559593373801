'use client';

import Script from 'next/script';
import type { ReactNode } from 'react';
import { useTimeout } from '$util/hooks/useTimeout';

interface LiveChatProps {
  livechatId: number;
  delayLoad?: number;
}

export function LiveChat({ livechatId, delayLoad = 0 }: LiveChatProps): ReactNode {
  /**
   * @note delaying the loading of the LiveChat script because
   * even with the `lazyOnload` strategy it still is a blocking script.
   */
  const ready = useTimeout(delayLoad);
  if (!ready) return null;

  return (
    <>
      <Script id="livechat-li" strategy="lazyOnload" async>
        {`
          window.__lc = window.__lc || {};
          window.__lc.license = ${livechatId};
        `}
      </Script>
      <Script id="livechat" src="https://cdn.livechatinc.com/tracking.js" strategy="lazyOnload" async />
    </>
  );
}
