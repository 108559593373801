//! client-only

declare global {
  interface Window {
    readonly OneTrust?: OneTrustInstance;
    readonly OnetrustActiveGroups?: string;
  }
}

interface OneTrustInstance {
  readonly ToggleInfoDisplay: () => void;
  readonly OnConsentChanged: (cb: () => void) => void;
}

export const ONETRUST_COOKIE_CATEGORIES = {
  NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
};

export const isFunctionalCategoryActive = (): boolean => {
  if (!window.OnetrustActiveGroups) return false;
  const activeOneTrustCookieGroups = window.OnetrustActiveGroups;
  return activeOneTrustCookieGroups.includes(ONETRUST_COOKIE_CATEGORIES.FUNCTIONAL);
};

export const isPerformanceCategoryActive = (): boolean => {
  if (!window.OnetrustActiveGroups) return false;
  const activeOneTrustCookieGroups = window.OnetrustActiveGroups;
  return activeOneTrustCookieGroups.includes(ONETRUST_COOKIE_CATEGORIES.PERFORMANCE);
};

export const isTargetingCategoryActive = (): boolean => {
  if (!window.OnetrustActiveGroups) return false;
  const activeOneTrustCookieGroups = window.OnetrustActiveGroups;
  return activeOneTrustCookieGroups.includes(ONETRUST_COOKIE_CATEGORIES.TARGETING);
};
