//! client-only

export const gtag = (...args: any[]) => {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'undefined') {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line func-names
      window.gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer!.push(arguments);
      };
    }

    window.gtag(...args);
  }

  window.dataLayer = window.dataLayer || [];
};
