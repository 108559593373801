import type { Locale } from '@fixter/i18n';

import {
  AppContext,
  /**
   * @note While we're migrating Page Router pages to App Router, the components will share context on both routers.
   */
  useAppContext as useDeprecatedAppContext,
} from '$util/AppContext';

export interface AppContextData {
  /**
   * Alternative to `window.location` that can be used in Client Components server-side.
   * @todo consider implementing `readLocation()` to not rely on client contexts
   */
  readonly location: URL;

  /**
   * Some Page Router code that leaks into App Router reads this.
   *
   * @deprecated Pass the data from Server Components to Client Components as props.
   * @see `readLocale()`
   */
  readonly locale: Locale;
}

export const useAppContext = useDeprecatedAppContext as () => AppContextData;

export { AppContext };
